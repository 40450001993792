<template>
  <div>
    <article class="courses-article">
      <v-card :loading="loading" class="ma-0 pa-0" max-width="600">
        <template slot="progress">
          <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>
        <!-- <v-img
          v-if="course.source === 'Code Clan'"
          src="../../../public/platform-logos/codeclan-logo.png"
        ></v-img>

        <v-img
          v-else-if="course.source === 'North East Scotland College'"
          src="../../../public/platform-logos/nescol-logo.png"
        ></v-img> -->
        <div class="home-img-container" v-if="course.source === 'CodeClan'">
          <img
            class="home-article-img"
            src="../../../public/platform-logos/codeclan-logo.png"
          />
        </div>
        <div
          class="home-img-container px-4"
          v-else-if="course.source === 'The Data Lab'"
        >
          <v-img contain class="home-article-img" :src="course.imgUrl"></v-img>
        </div>
        <div
          class="home-img-container"
          v-else-if="course.source === 'North East Scotland College'"
        >
          <img
            class="home-article-img"
            src="../../../public/platform-logos/nescol-logo.png"
          />
        </div>
        <div class="home-img-container" v-else>
          <img class="home-article-img" :src="course.imgUrl" />
        </div>

        <v-card-title class="partner-title">{{
          course.title.toUpperCase()
        }}</v-card-title>
        <v-card-subtitle class="course-subtitle">{{
          course.source
        }}</v-card-subtitle>
        <v-chip
          class="chip"
          label
          link
          v-for="tag in courseTags"
          :key="tag"
          @click="filterTag(tag)"
        >
          {{ tag }}
        </v-chip>
        <v-card-text>
          {{ course.summary }}
        </v-card-text>

        <v-divider class="mx-4"></v-divider>

        <v-card-actions>
          <v-btn
            color="#75bdc7"
            text
            target="_blank"
            v-bind:href="course.urlLink"
          >
            Learn More
          </v-btn>
        </v-card-actions>
      </v-card>
    </article>
  </div>
</template>
<script>
export default {
  props: ["course", "loading", "mobile"],
  computed: {
    courseTags() {
      var courseTags = [];

      courseTags = courseTags.concat(this.course.tags);

      if (
        this.course.info[0].attendance != null &&
        this.course.info[0].attendance != ""
      ) {
        courseTags.push(this.course.info[0].attendance);
      }

      if (this.course.info[0].term != null && this.course.info[0].term != "") {
        courseTags.push(this.course.info[0].term);
      }

      if (this.course.info[0].type != null && this.course.info[0].type != "") {
        courseTags.push(this.course.info[0].type);
      }

      courseTags = courseTags.filter(t => t != "");

      return courseTags;
    }
  },
  methods: {
    filterTag(tag) {
      this.$emit("filterTag", tag);
    }
  }
};
</script>
<style scoped>
@media (min-width: 320px) {
  .partner-title {
    font-size: 15px !important;
  }
}
@media (min-width: 1025px) {
  .partner-title {
    font-size: 15px !important;
  }
}

.partner-title {
  word-break: normal;
}

.course-img-container {
  /* max-width: 100%; */
}

.home-article-img {
  /* Rectangle 6 */
  width: 100%;
  height: 166px;
  object-fit: contain;
  background-color: white;
}

div.home-img-container {
  max-width: 100%;
}

.course-logo-img {
  /* Rectangle 6 */
  /* width: 100%;
  height: 166px; */
}

/* .courses-article {
  padding: 0 1rem 0 1rem;
} */

.course-img {
  width: 100%;
  height: 166px;
  object-fit: cover;
}

.img-container-course {
  max-width: 100%;
  padding: 0 0 0.25rem 0;
}

.course-source {
  position: static;
  width: 253px;
  height: 20px;
  left: 0px;
  top: 0px;

  font-family: Lora;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;

  display: flex;
  align-items: center;
  letter-spacing: 0.16em;

  color: #4e2a69;
}

.course-accent {
  width: 100%;
  height: 2px;
  left: 0px;
  top: 20px;

  border-color: #4e2a69;
}

.course-subtitle {
  margin-bottom: -22px;
}

.course-title {
  position: static;
  width: 327px;
  left: 0px;
  top: 22px;

  font-family: Playfair Display;
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 38px;

  display: flex;
  align-items: center;

  color: #323b3c;
}

.course-summary {
  position: static;
  width: 327px;
  left: 0px;
  top: 99px;

  font-family: Lora;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;

  display: flex;
  align-items: center;

  color: #516163;
}

.learn-more {
  position: static;
  width: 76px;
  height: 20px;
  left: 0px;
  top: 202px;

  font-family: Lora;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  display: flex;
  align-items: center;

  text-decoration-line: underline;

  color: #4e2a69 !important;
}

.chip {
  justify-content: center;
  width: 106px;
  height: 28px !important;
  left: 10px;
  top: 18px;
  background: #19365e !important;
  border-radius: 5px;
  margin-bottom: 1rem;
  margin-right: 8px;
  padding-left: 5px;
  padding-right: 5px;

  font-family: Big Caslon;
  font-style: normal;
  font-weight: normal;
  font-size: 10px !important;
  line-height: 18px;
  color: #fff !important;
}

.chip-divider {
  margin-top: 12px;
}
</style>
