<template>
  <v-container>
    <v-combobox
      v-model="chips"
      :items="filterTags"
      chips
      clearable
      label="Filter by category"
      item-value="title"
      item-text="title"
      multiple
      prepend-icon="mdi-filter-variant"
      solo
    >
      <template v-slot:selection="{ attrs, item, select, selected }">
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          close
          @click="select"
          @click:close="remove(item)"
        >
          <strong class="mr-1">{{ item.title }}</strong>
          <span>({{ item.type }})</span>
        </v-chip>
      </template>
    </v-combobox>

    <!-- NO DATA AVAILABLE -->

    <div v-if="courses.length === 0">
      <p v-show="!firstLoad">No data available for this partner</p>
    </div>

    <v-row v-bind:class="{ 'course-row': loading }">
      <v-data-iterator
        :items="courses || coursesLHS"
        :search="search"
        hide-default-footer
        :items-per-page="-1"
        loading="true"
        loading-text=""
      >
        <template v-slot:default="props">
          <v-row>
            <v-col
              cols="12"
              md="4"
              class="courses-col"
              v-for="course in props.items"
              :key="course.id"
            >
              <v-skeleton-loader
                v-if="firstLoad"
                type="image, article"
              ></v-skeleton-loader>
              <course
                :course="course"
                v-show="!firstLoad"
                :mobile="mobile"
                @filterTag="filterByTag"
              ></course>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-row>
  </v-container>
</template>
<script>
import course from "@/components/course/course.vue";

export default {
  created() {
    this.isMobile();
  },
  components: { course },
  data() {
    return {
      selectedTag: null,
      drawer: true,
      loading: true,
      firstLoad: true,
      mobile: 600,
      search: "",
      chips: [],
      sections: [
        { title: "News" },
        { title: "Search" },
        { title: "Courses" },
        { title: "Events" }
      ]
    };
  },
  watch: {
    getProvider(partner) {
      this.$router
        .push({ name: "Courses", query: { partner } })
        .catch(() => {});
    }
  },
  computed: {
    courses() {
      var courses = this.$store.getters["courses/getCourses"];
      var provider = this.getProvider;
      var filterCourses = [];

      courses.sort((a, b) => {
        let fa = a.title.toLowerCase(),
          fb = b.title.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      if (this.getProvider && this.getProvider !== "All Courses") {
        courses = courses.filter(course => {
          return course.source === provider;
        });
      }

      if (this.getSearchQuery) {
        courses = courses.filter(course => {
          return (
            course.summary
              .toLowerCase()
              .includes(this.getSearchQuery.toLowerCase()) ||
            course.title
              .toLowerCase()
              .includes(this.getSearchQuery.toLowerCase()) ||
            course.source
              .toLowerCase()
              .includes(this.getSearchQuery.toLowerCase())
          );
        });
        return courses;
      }

      if (this.chips.length === 0) {
        return courses;
      }

      // only show course where it contains every tag in the filter chips
      var chipsMapped = this.chips.map(c => c.title);
      courses.forEach(course => {
        course.tags.forEach(tag => {
          if (chipsMapped.includes(tag)) {
            filterCourses.push(course);
          }
        });
        // if it's not already been added from the course tags
        if (!filterCourses.includes(course)) {
          if (
            course.info[0].term != null &&
            chipsMapped.includes(course.info[0].term)
          ) {
            filterCourses.push(course);
          } else if (
            course.info[0].level != null &&
            chipsMapped.includes(course.info[0].level)
          ) {
            filterCourses.push(course);
          } else if (
            course.info[0].attendance != null &&
            chipsMapped.includes(course.info[0].attendance)
          ) {
            filterCourses.push(course);
          } else if (
            course.info[0].type != null &&
            chipsMapped.includes(course.info[0].type)
          ) {
            filterCourses.push(course);
          }
        }
      });

      return filterCourses;
    },
    getSearchQuery() {
      return this.$store.getters["search/getQuery"];
    },
    coursesLHS() {
      var courses = JSON.parse(
        JSON.stringify(this.$store.getters["courses/getCourses"])
      );

      if (this.getProvider === null || this.getProvider === "All News") {
        return courses;
      }

      const filteredCourses = courses.filter(course =>
        course.source.includes(this.getProvider)
      );

      return filteredCourses;
    },
    /**
     * Add all the different tag types together
     */
    filterTags() {
      var filterTags = [];

      filterTags.push({ header: "Interest" });
      filterTags = filterTags.concat(this.courseTags);
      filterTags.push({ header: "Term" });
      filterTags = filterTags.concat(this.termTags);
      filterTags.push({ header: "Attendance" });
      filterTags = filterTags.concat(this.attendanceTags);
      filterTags.push({ header: "Qualification" });
      filterTags = filterTags.concat(this.levelTags);

      return filterTags;
    },
    courseTags() {
      var courses = this.$store.getters["courses/getCourses"];
      var allCoursesTags = [];
      courses.forEach(course => {
        if (course === " ") {
          console.log("empty tag");
        }
        allCoursesTags.push(course.tags);
      });
      var mergeAllTags = [].concat(...allCoursesTags);
      let unique = [...new Set(mergeAllTags)];

      var tagObjs = [];

      unique.forEach(tag => {
        var newTag = {
          title: tag,
          type: "course"
        };
        tagObjs.push(newTag);
      });

      tagObjs.sort((a, b) => {
        let fa = a.title.toLowerCase(),
          fb = b.title.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      return tagObjs;
    },
    attendanceTags() {
      var courses = this.$store.getters["courses/getCourses"];
      var attendanceTags = [];
      courses.forEach(course => {
        if (
          course.info[0].attendance != null &&
          course.info[0].attendance != ""
        ) {
          attendanceTags.push({
            title: course.info[0].attendance,
            type: "attendance"
          });
        }
      });

      return attendanceTags;
    },
    termTags() {
      var courses = this.$store.getters["courses/getCourses"];
      var termTags = [];
      courses.forEach(course => {
        if (course.info[0].term != null && course.info[0].term != "") {
          termTags.push({
            title: course.info[0].term,
            type: "term"
          });
        }
        if (course.info[0].type != null && course.info[0].type != "") {
          termTags.push({
            title: course.info[0].type,
            type: "term"
          });
        }
      });

      termTags.sort((a, b) => {
        let fa = a.title.toLowerCase(),
          fb = b.title.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      return termTags;
    },
    levelTags() {
      var courses = this.$store.getters["courses/getCourses"];
      var levelTags = [];
      courses.forEach(course => {
        if (course.info[0].level != null && course.info[0].level != "") {
          levelTags.push({
            title: course.info[0].level,
            type: "qualification"
          });
        }
      });

      levelTags.sort((a, b) => {
        let fa = a.title.toLowerCase(),
          fb = b.title.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      return levelTags;
    },
    getProvider() {
      return this.$store.getters["articles/getSelectedProvider"];
    },
    getFilteredCourses() {
      return this.$store.getters["courses/getFilteredCourses"];
    }
  },
  mounted() {
    let scope = this;
    const route = this.$route;

    if (route.query && route.query.partner) {
      // Partner filtering using direct URLs
      this.$store.commit("articles/setSelectedProvider", route.query.partner);
    }

    this.$store.dispatch("courses/retrieveCourses").then(() => {
      setTimeout(function() {
        scope.firstLoad = false;
      }, 50);
    });
  },
  methods: {
    filterByTag(tag) {
      tag = this.filterTags.find(t => t.title === tag);

      if (!this.chips.some(t => t.title === tag.title)) {
        this.chips.push(tag);
      }
    },
    remove(item) {
      this.chips.splice(this.chips.indexOf(item), 1);
      this.chips = [...this.chips];
    },
    goTo(section) {
      if (section.title === "News") {
        this.$router.push({ name: "News" });
      } else {
        this.$router.push("/" + section.title.toLowerCase()).catch(() => {});
      }
    },
    isMobile() {
      if (screen.width <= 1024) {
        this.drawer = false;
        this.mobile = 365;
      } else {
        this.drawer = true;
        this.mobile = 600;
      }
    }
  }
};
</script>
<style scoped>
.search-col {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.course-row {
  display: initial;
}

.courses-col {
  padding-top: 0rem;
}

.chip-button {
  justify-content: center;
  width: 92px;
  height: 28px !important;
  left: 10px;
  top: 18px;
  background: #19365e !important;
  border-radius: 5px;
  margin-bottom: 1rem;
  margin-right: 8px;

  font-family: Big Caslon;
  font-style: normal;
  font-weight: normal;
  font-size: 10px !important;
  line-height: 18px;
  color: #fff !important;
}

.search-box {
  background-color: aqua;
}
</style>
